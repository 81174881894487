import React, { memo } from "react"
import PurpleSolidButton from "../../../components/Buttons/PurpleSolidButton"
import RedSolidButton from "../../../components/Buttons/RedSolidButton"
const EndIndicator = ({ closeTest, resetTest }) => {
  return (
    <div className="prose-sm prose text-center sm:prose lg:prose-lg xl:prose-xl">
      <p className="lead">
        <span role="img" aria-label="toutes nos félicitations">
          🎉
        </span>
        Vous avez terminé avec succès le test des pixels morts
        <span role="img" aria-label="toutes nos félicitations">
          🎉
        </span>
      </p>
      <p className="lead">
        J'espère qu'il n'y a pas de pixels morts sur votre écran.
      </p>
      <p className="lead">
        Vous pouvez appuyer sur{" "}
        <span className="px-2 py-1 text-sm font-bold text-red-800 bg-red-100 border-red-300 rounded-sm shadow-sm">
          ESC
        </span>{" "}
        pour quitter le test.
      </p>
      <div>
        <RedSolidButton
          text="Sortir"
          onClick={closeTest}
          style={{ marginRight: "8px" }}
        />
        <PurpleSolidButton text="Retester" onClick={resetTest} />
      </div>
    </div>
  )
}
export default memo(EndIndicator)
