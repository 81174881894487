import { Link } from "gatsby"
import React, { useRef } from "react"
import PurpleSolidButton from "../../../components/Buttons/PurpleSolidButton"

import PikselFs from "../../../components/fullscreen/PikselFs"
import Seo from "../../../components/Seo"
import OluPikselGaleri from "../../../contents/Gallery/OluPikselGaleri"
import SvgLeftIcon from "../../../contents/Icons/SvgLeftIcon"
import SvgRightIcon from "../../../contents/Icons/SvgRightIcon"
import SvgPixelTest from "../../../contents/SVG/SvgPixelTest"
import EndIndicator from "../components/EndIndicator"
import StartIndicator from "../components/StartIndicator"
const DeadPixelTest = () => {
  const refPikselFs = useRef()

  return (
    <>
      <Seo
        lang="fr"
        keywords="test de pixel mort, test de pixel bloqué, réparation de pixel bloqué, réparation de pixel mort, test de pixel bloqué, qu'est-ce qu'un pixel mort, qu'est-ce qu'un pixel bloqué, qu'est-ce qu'un pixel bloqué, pixel mort différence de pixel bloqué, test de pixel mort, test de pixel, pixel mort, pixel mort, test de pixel, pixel bloqué, réparation de pixel en ligne"
        title="Test des pixels morts et bloqués"
        description="Testez les pixels morts et les pixels bloqués sur votre Android, téléphones iPhone, tablette, PC, télévision intelligente, moniteur et écran d'ordinateur sur le site gratuit sans aucune installation."
      />
      <h1 className="text-center capitalize md:text-left">
        Test de pixels morts ou de pixels bloqués
      </h1>
      <p className="lead">
        Vous pouvez détecter les pixels morts ou les pixels bloqués sur votre
        écran, le cas échéant, en cliquant sur le bouton{" "}
        <code>Test Dead - Stuck Pixels</code> ci-dessous sans aucune
        configuration.
      </p>

      <div
        className="px-4 py-3 text-blue-900 bg-blue-100 border-t-4 border-blue-500 rounded-b shadow-md"
        role="alert"
      >
        <p className="font-bold">
          Avant de commencer le test Dead - Stuck Pixel, vous devriez
          considérer:
        </p>
        <p className="">
          Avant de faire le test des pixels morts, assurez-vous que votre écran
          est propre et que votre environnement n'est pas trop lumineux. Alors
          que la poussière sur votre écran peut vous induire en erreur, une
          luminosité excessive peut vous faire manquer des pixels morts.
        </p>
      </div>

      <div className="mt-10 text-center">
        <PurpleSolidButton
          text="Test Dead - Stuck Pixels"
          onClick={() => refPikselFs.current.open()}
        />
      </div>
      <h2 className="capitalize">
        Comment puis-je tester les pixels morts ou les pixels bloqués?
      </h2>
      <p>
        Après avoir cliqué sur le bouton <code>Test Dead - Stuck Pixels</code>{" "}
        ci-dessus, le site passera en plein écran. Des informations sont
        disponibles sur l'écran qui s'ouvre.
      </p>
      <p>
        Pour détecter les pixels morts ou bloqués, vous devez vérifier les
        couleurs rouge, verte, bleue, noire et blanche qui occupent le plein
        écran une par une.
      </p>

      <SvgPixelTest className="max-w-full px-3 md:px-10" />
      <p>
        Pour basculer entre les couleurs en plein écran, vous pouvez cliquer
        n'importe où sur l'écran avec le bouton gauche de la souris ou appuyer
        sur les touches{" "}
        <SvgLeftIcon className="inline text-purple-700 fill-current" /> et
        <SvgRightIcon className="inline text-purple-700 fill-current" /> (gauche
        et droite) de votre clavier.
      </p>
      <blockquote>
        Avant de commencer le test de pixel mort - pixel bloqué, veuillez faire
        attention à l'avertissement au-dessus de la zone d'information bleue en
        haut.
      </blockquote>
      <p>
        Si vous détectez un ou plusieurs points avec une couleur différente de
        la couleur d'arrière-plan couvrant votre écran, cela signifie que vous
        avez un pixel mort ou un pixel bloqué sur votre écran.
      </p>

      <blockquote>
        Vous pouvez appuyer sur{" "}
        <span className="px-2 py-1 text-sm font-bold text-red-800 bg-red-100 border-red-300 rounded-sm shadow-sm">
          ESC
        </span>{" "}
        pour quitter le test.
      </blockquote>
      <p>
        Vous pouvez afficher des exemples d'images de pixels morts ou de pixels
        bloqués en cliquant sur le bouton{" "}
        <code className="capitalize">
          Échantillons de pixels morts - bloqués
        </code>{" "}
        ci-dessous.
      </p>
      <OluPikselGaleri text="Échantillons de pixels morts - bloqués" />
      <h2>Qu'est-ce que Dead Pixel?</h2>
      <p>
        <strong>Dead Pixel</strong> est un pixel qui ne fonctionne pas sur votre
        écran car il ne reçoit pas d'énergie. Pour cette raison, les pixels
        morts sont généralement de couleur noire.
      </p>
      <p>
        Bien que les pixels morts soient pour la plupart noirs, ils peuvent être
        blancs sur certains écrans.
      </p>
      <p>
        Pour plus d'informations sur la réparation des pixels morts et des
        pixels morts, vous pouvez accéder à la page correspondante en cliquant
        sur le bouton{" "}
        <code className="capitalize">Réparation des pixels morts</code>{" "}
        ci-dessous.
      </p>

      <div className="my-3 text-center">
        <Link
          to="/correction-des-pixels-morts"
          style={{ textDecoration: "none" }}
          className="focus:outline-none text-white py-2.5 px-5 rounded-md bg-purple-500 hover:bg-purple-600 hover:shadow-lg text-base md:text-lg"
        >
          <span className="text-white capitalize">
            Réparation des pixels morts
          </span>
        </Link>
      </div>

      <h2>Qu'est-ce que Stuck Pixel?</h2>
      <p className="lead">
        Contrairement aux <strong>pixels morts</strong>, les pixels bloqués
        reçoivent toujours de l'énergie, c'est pourquoi la réparation des pixels
        bloqués est beaucoup plus facile.
      </p>
      <p>
        Les pixels bloqués sont des pixels qui restent principalement dans des
        couleurs de base telles que{" "}
        <span className="text-red-600">le rouge</span>,{" "}
        <span className="text-green-400">le vert</span>,{" "}
        <span className="text-blue-600">le bleu</span> pour des raisons telles
        que le maintien de la même couleur pendant une longue période et qui
        reflètent la même couleur en continu.
      </p>
      <p>
        Pour plus d'informations sur les pixels bloqués et la réparation des
        pixels bloqués, vous pouvez accéder à la page correspondante en cliquant
        sur le bouton{" "}
        <code className="capitalize">Réparation des pixels bloqués</code>{" "}
        ci-dessous.
      </p>
      <div className="my-3 text-center">
        <Link
          to="/correction-des-pixels-bloques"
          style={{ textDecoration: "none" }}
          className="focus:outline-none text-white py-2.5 px-5 rounded-md bg-purple-500 hover:bg-purple-600 hover:shadow-lg text-base md:text-lg"
        >
          <span className="text-white capitalize">
            Réparation des pixels bloqués
          </span>
        </Link>
      </div>
      <PikselFs
        ref={refPikselFs}
        startIndicator={<StartIndicator />}
        endIndicator={
          <EndIndicator
            closeTest={() => refPikselFs.current.close()}
            resetTest={() => refPikselFs.current.reset()}
          />
        }
      />
    </>
  )
}
export default DeadPixelTest
