import React, { memo } from "react"
import SvgRightIcon from "../../../contents/Icons/SvgRightIcon"
const StartIndicator = () => {
  return (
    <div className="prose-sm prose text-center sm:prose lg:prose-lg xl:prose-xl">
      <p className="lead">
        Vous pouvez démarrer le test des pixels morts en cliquant n'importe où
        sur l'écran ou en appuyant sur la touche{" "}
        <SvgRightIcon className="inline text-purple-700 fill-current" /> (à
        droite) de votre clavier.
      </p>
      <p className="lead">
        Vous pouvez appuyer sur{" "}
        <span className="px-2 py-1 text-sm font-bold text-red-800 bg-red-100 border-red-300 rounded-sm shadow-sm">
          ESC
        </span>{" "}
        pour quitter le test.
      </p>
    </div>
  )
}
export default memo(StartIndicator)
